<template>
  <v-row id="template-form-page">
    <v-col cols="12">
        <v-card>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="saveTemplate">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Template Name</v-subheader>
                            <v-text-field  ref="templateName" v-model="model.templateName" :error-messages="$helpers.errorMsg('name', $v.model.templateName, 'Template Name')"
                                placeholder="Template Name" outlined hide-details="auto" required/>
                        </v-col>
                        <v-col cols="12">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Template Body</v-subheader>
                            <div class="send-mail" :class="{'error-border': submit && $helpers.errorMsg('name', $v.model.templateBody, 'Template Body').length}">
                                <quill-editor ref="templateBody"  v-model="model.templateBody" :options="editorOption"/>
                            </div>
                            <div v-if="submit && $helpers.errorMsg('name', $v.model.templateBody, 'Template Body').length" class="error-message mt-2 ml-2">
                                Template Body is required
                            </div>
                        </v-col>
                    </v-row>
                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="primary"  class="mr-4" :loading="loading" :disabled="loading">
                            Submit
                        </v-btn>
                        <v-btn type="button" outlined color="primary" class="mr-4" :disabled="loading" @click.native="$router.push('/email-templates')">
                            Cancel
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from 'vuex';

import { EDITOR_OPTIONS } from '@/constants/editor-options';

export default {
    data: (vm) => ({
        emailTemplateId: vm.$route.params.emailTemplateId,
        editorOption: EDITOR_OPTIONS('the body template'),
        submit: false,
        loading: false,
        model: {
            templateName: "",
            templateBody: ""
        },
    }),
    validations: {
        model: {
            templateName: { required},
            templateBody: { required}
        }
    },
    created() {
        if (this.emailTemplateId != 'create') {
          this.getEmailTempModel(this.emailTemplateId).then(response => this.model = response);
        }
    },
    methods: {
        ...mapActions(['getEmailTempModel', 'createEmailTempModel', 'updateSelectedEmailTemp']),

        saveTemplate() {
            this.submit = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.submit = false;
            this.loading = true;
            if (this.emailTemplateId != 'create') {
                this.updateSelectedEmailTemp(this.model).then(resp => this.$router.push("/email-templates"))
                    .catch(err => this.loading = false);
            } else {
                this.createEmailTempModel(this.model).then(resp => this.$router.push("/email-templates"))
                    .catch(err => this.loading = false);
            }
        },
    },
};
</script>
